import React from "react";

function TextMenu({
  products,
  brandImage,
  brandPrimaryHex,
  darkmode,
  surchargeMessage,
}) {
  const apronImageUrl = brandImage;
  const backgroundColor = darkmode === "light" ? "#f5f4ee" : "#333333";
  const textColor = darkmode === "light" ? "#333" : "#fff";
  const primaryColor = brandPrimaryHex || "#1e90ff";

  const apronSidebarStyle = {
    // width: "20%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    backgroundColor: brandPrimaryHex,
    flexShrink: 0, // Prevent the sidebar from shrinking
    overflow: "hidden", // Prevent content spilling
  };

  const apronImageStyle = {
    maxHeight: "100%",
    objectFit: "contain",
  };

  const containerStyle = {
    display: "flex", // Flex container for the layout
    position: "relative",
    width: "100vw", // Full width
    height: "100vh", // Full height
    backgroundColor: backgroundColor,
    color: textColor,
  };

  const bottomRightMessageStyle = {
    position: "absolute",
    bottom: "2rem",
    right: "2rem",
    fontStyle: "italic",
    fontSize: "24px",
    color: primaryColor,
    ...(darkmode === "dark" && {
      color: "#fff",
    }),
  };

  const menuContentStyle = {
    flexGrow: 1, // Take the remaining space
    padding: "3rem",
    overflowY: "auto",
  };

  const sectionTitleStyle = {
    fontSize: "72px",
    fontFamily: "Athletics-BOLD, sans-serif",
    color: primaryColor,
    ...(darkmode === "dark" && {
      color: "#fff",
    }),
    marginBottom: "1rem",
  };

  const itemContainerStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    marginBottom: "1.5rem",
  };

  const itemNameContainerStyle = {
    display: "flex",
    flexDirection: "column",
    maxWidth: "80%",
  };

  const itemNameWithBesideStyle = {
    fontSize: "64px",
    fontFamily: "Athletics-MEDIUM, sans-serif",
    lineHeight: "1.2",
    color: textColor,
    display: "inline",
  };

  const besideStyle = {
    fontSize: "34px",
    fontStyle: "italic",
    color: textColor,
    display: "inline",
    marginLeft: "8px",
  };

  const belowStyle = {
    fontSize: "32px",
    fontStyle: "italic",
    marginTop: "4px",
    color: textColor,
  };

  const priceStyle = {
    color: primaryColor,
    ...(darkmode === "dark" && {
      color: "#fff",
    }),
    fontFamily: "Athletics-BOLD, sans-serif",
    fontSize: "64px",
    minWidth: "100px",
    textAlign: "right",
  };

  return (
    <div style={containerStyle}>
      {/* Apron Sidebar */}
      <div style={apronSidebarStyle}>
        <img src={apronImageUrl} alt="Apron" style={apronImageStyle} />
      </div>

      {/* Menu Content */}
      <div style={menuContentStyle}>
        {products.map((product) => (
          <div key={product.id}>
            {product.type === "hdr" ? (
              <div style={sectionTitleStyle}>{product.DisplayName}</div>
            ) : (
              <div style={itemContainerStyle}>
                <div style={itemNameContainerStyle}>
                  <div>
                    {/* Product Name with DescBeside as a continuation */}
                    <span style={itemNameWithBesideStyle}>
                      {product.DisplayName}
                    </span>
                    {product.DescBeside && (
                      <span style={besideStyle}>{product.DescBeside}</span>
                    )}
                  </div>
                  {product.DescBelow && (
                    <div style={belowStyle}>{product.DescBelow}</div>
                  )}
                </div>
                <div style={priceStyle}>{product.Price}</div>
              </div>
            )}
          </div>
        ))}
      </div>

      {/* Bottom Right Message */}
      <div style={bottomRightMessageStyle}>{surchargeMessage}</div>
    </div>
  );
}

export default TextMenu;
