import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import ImageSlide from "./components/ImageSlide";
import TextSlide from "./components/TextSlide";
import ExternalUrlSlide from "./components/ExternalUrl";
import ExternalVideoSlide from "./components/ExternalVideo";
import Loading from "./components/Loading";
import ErrorPage from "./components/ErrorPage";
import Promotion from "./components/Promotion";
import ClosedSlide from "./components/ClosedSlide";
import useWebSocket from "./hooks/useWebSocket";

function SlideBuilder() {
  const { ip } = useParams();
  const [slides, setSlides] = useState([]);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [brand, setBrand] = useState(null);
  const [brandImage, setBrandImage] = useState(null); // New state for brand image
  const [brandColours, setBrandColours] = useState(null);
  const [brandPrimaryHex, setBrandPrimaryHex] = useState(null); // Primary Hex Color
  const [brandSecondaryHex, setBrandSecondaryHex] = useState(null); // Secondary Hex Color
  const [darkmode, setDarkmode] = useState(null);
  const [videoPlaying, setVideoPlaying] = useState(false);
  const [promotion, setPromotion] = useState({ active: false });
  const [venue, setVenue] = useState(null);
  const [openTime, setOpenTime] = useState(null);
  const [statusmessage, setStatusMessage] = useState(null);
  const [surchargeMessage, setSurchargeMessage] = useState(null);
  const [companyDetails, setCompanyDetails] = useState("null");

  useEffect(() => {
    const fetchSurchargeMessage = async () => {
      try {
        const response = await fetch("/api/config/surcharge");
        const result = await response.json();
        setSurchargeMessage(result.surchargemessage);
      } catch (error) {
        console.error("Error fetching surcharge message:", error);
        setSurchargeMessage("");
      }
    };

    fetchSurchargeMessage();
  }, []);

  useEffect(() => {
    const fetchCompanyDetails = async () => {
      try {
        const response = await fetch("/api/config/company-details");
        const result = await response.json();
        setCompanyDetails(result);
      } catch (error) {
        console.error("Error fetching surcharge message:", error);
        setCompanyDetails("");
      }
    };

    fetchCompanyDetails();
  }, []);

  const fetchSlides = useCallback(async () => {
    try {
      const response = await fetch(`/api/slidebuilder/${ip}`);
      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || "Failed to fetch slides data.");
      }

      setSlides(data.slides);
      setBrand(data.brand);
      setDarkmode(data.darkmode);
      setVenue(data.venue);
      setOpenTime(data.openTime);
      setStatusMessage(data.statusmessage);
      setBrandImage(data.brandImage);
      setBrandPrimaryHex(data.brandPrimaryHex);
      setBrandSecondaryHex(data.brandSecondaryHex);

      // Fetch additional brand colors if brand ID is available
      if (data.brand) {
        const brandResponse = await fetch(`/api/theme/${data.brand}`);
        const brandData = await brandResponse.json();

        if (!brandResponse.ok) {
          throw new Error(brandData.error || "Failed to fetch brand options.");
        }
        setBrandColours(brandData.theme);
      }
    } catch (error) {
      console.error("Fetch error:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, [ip]);

  useEffect(() => {
    fetchSlides();
  }, [fetchSlides]);

  useWebSocket(ip, setDarkmode, setPromotion, fetchSlides);

  useEffect(() => {
    if (slides.length > 0 && !videoPlaying) {
      const interval = slides[currentSlideIndex]?.interval || 5000;

      const timer = setTimeout(() => {
        setCurrentSlideIndex((prevIndex) => (prevIndex + 1) % slides.length);
      }, interval);

      return () => clearTimeout(timer);
    }
  }, [currentSlideIndex, slides, videoPlaying]);

  const handleVideoEnd = () => {
    setVideoPlaying(false);
    setCurrentSlideIndex((prevIndex) => (prevIndex + 1) % slides.length);
  };

  if (loading) return <Loading />;
  if (error)
    return <ErrorPage error={error} ip={ip} companyDetails={companyDetails} />;
  if (!slides.length) return <div>No slides available</div>;

  const backgroundColor = darkmode === "light" ? "#f5f5f5" : "#333333";

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        height: "100%",
        overflow: "hidden",
        backgroundColor: backgroundColor, // Ensure background color is consistently applied
      }}
    >
      <Promotion promotion={promotion} />

      {slides.map((slide, index) => (
        <div
          key={index}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: index === currentSlideIndex ? "block" : "none", // Switch slides instantly
            backgroundColor: backgroundColor, // Ensure each slide matches the container's background
          }}
        >
          {(slide.slidetype === "closed" || slide.type === "preopen") && (
            <ClosedSlide
              status={slide.type}
              venue={venue}
              brand={brand}
              companyDetails={companyDetails}
              brandImage={brandImage}
              brandColours={brandColours}
              brandPrimaryHex={brandPrimaryHex}
              brandSecondaryHex={brandSecondaryHex}
              darkmode={darkmode}
              openTime={openTime}
              statusmessage={statusmessage}
            />
          )}
          {slide.slidetype === "2x2images" && (
            <ImageSlide
              products={slide.products.map((product) => ({
                ...product,
                Image: product.Image || null,
              }))}
              brand={brand}
              brandImage={brandImage}
              brandColours={brandColours}
              brandPrimaryHex={brandPrimaryHex}
              brandSecondaryHex={brandSecondaryHex}
              darkmode={darkmode}
              surchargeMessage={surchargeMessage}
            />
          )}
          {slide.slidetype === "ExternalMedia" && (
            <ExternalUrlSlide url={slide.mediaurl} slideNo={slide.slide_no} />
          )}
          {slide.slidetype === "dynamictext" && (
            <TextSlide
              products={slide.products}
              brand={brand}
              brandImage={brandImage}
              brandColours={brandColours}
              brandPrimaryHex={brandPrimaryHex}
              brandSecondaryHex={brandSecondaryHex}
              darkmode={darkmode}
              surchargeMessage={surchargeMessage}
            />
          )}
          {slide.slidetype === "video" && (
            <ExternalVideoSlide
              url={slide.mediaurl}
              isActive={index === currentSlideIndex}
              onPlay={() => setVideoPlaying(true)}
              onEnd={handleVideoEnd}
            />
          )}
        </div>
      ))}
    </div>
  );
}

export default SlideBuilder;
