import { useEffect } from "react";

const useWebSocket = (ip, setDarkmode, setPromotion, fetchSlides) => {
  useEffect(() => {
    let ws;
    let reconnectTimeout;

    const connect = () => {
      ws = new WebSocket(
        `${window.location.origin.replace(
          /^http/,
          "ws"
        )}/api/liveupdate?ip=${ip}`
      );

      const pingInterval = 30000;

      const sendPing = () => {
        if (ws.readyState === WebSocket.OPEN) {
          ws.send(JSON.stringify({ type: "ping" }));
        }
      };

      // Set an interval to send pings
      const pingIntervalId = setInterval(sendPing, pingInterval);

      ws.onopen = () => {
        console.log("WebSocket connection established");
      };

      ws.onmessage = (event) => {
        const message = JSON.parse(event.data);

        // Handle different types of WebSocket messages
        if (message.type === "update" && message.ip === ip) {
          fetchSlides();
        }
        if (message.darkmode) {
          setDarkmode(message.darkmode); // Set darkmode state
        }
        if (message.promotion) {
          setPromotion(message.promotion); // Set promotion state
        }
      };

      ws.onclose = () => {
        clearInterval(pingIntervalId); // Clear the ping interval if connection closes
        console.log("WebSocket connection closed. Attempting to reconnect...");
        reconnectTimeout = setTimeout(connect, 5000); // Attempt to reconnect after 5 seconds
      };

      ws.onerror = (error) => {
        console.error("WebSocket error:", error);
        ws.close(); // Ensure the connection is closed on error
      };
    };

    connect();

    return () => {
      ws.close();
      clearTimeout(reconnectTimeout);
    };
  }, [ip, setDarkmode, setPromotion, fetchSlides]);
};

export default useWebSocket;
