import React from "react";

const containerStyle = {
  position: "relative",
  width: "100%",
  height: "100%",
};

const headerStyle = {
  position: "absolute",
  bottom: 0,
  width: "100%",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  color: "white",
  display: "flex",
  justifyContent: "space-between",
  paddingBottom: "10px",
};

const Home = () => {
  return (
    <div style={containerStyle}>
      <img
        src={`${process.env.PUBLIC_URL}/venuesnsw.png`}
        alt="Venues NSW"
        width="1920"
        height="1080"
      />
      <div style={headerStyle}>
        <span>Menuboard Generator</span>
        <span>Build ID: {process.env.REACT_APP_BUILD_ID}</span>
      </div>
    </div>
  );
};

export default Home;
