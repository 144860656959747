import { useEffect } from "react";

const apronSidebarStyle = {
  width: "20%",
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
};

const apronImageStyle = {
  maxHeight: "100%",
  objectFit: "contain",
};

const logoStyle = {
  position: "absolute",
  bottom: "20px",
  right: "20px",
  maxHeight: "150px",
};

const ClosedSlide = ({
  companyDetails,
  brandPrimaryHex,
  status,
  brand,
  brandImage,
  darkmode,
  openTime,
  statusmessage,
}) => {
  const backgroundColor =
    darkmode === "dark" ? "#000000" : brandPrimaryHex || "#ffffff";
  const apronImageUrl = brandImage;
  const logoUrl = companyDetails.companyLogo;

  const sectionTitleStyle = {
    fontSize: "72px",
    fontFamily: "Athletics-BOLD, sans-serif",
    color: "#fff",
    marginBottom: "1rem",
  };

  const statusStyle = {
    fontSize: "64px",
    fontFamily: "Athletics-MEDIUM, sans-serif",
    lineHeight: "1.2",
    color: "#fff",
  };

  const formatOpenTime = (time) => {
    if (!time || !/^(\d{2}):(\d{2}):(\d{2})$/.test(time)) {
      console.warn("Invalid openTime format:", time);
      return "";
    }

    const [hours, minutes] = time.split(":").map(Number);
    const date = new Date();
    date.setHours(hours, minutes);

    return date.toLocaleTimeString([], {
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    });
  };

  const formattedOpenTime = formatOpenTime(openTime);

  useEffect(() => {
    const calculateTimeUntilOpen = () => {
      const now = new Date();
      if (!openTime || !/^(\d{2}):(\d{2}):(\d{2})$/.test(openTime)) {
        console.warn("Invalid or missing openTime format:", openTime);
        return null;
      }

      const [hours, minutes, seconds] = openTime.split(":").map(Number);
      const openDate = new Date();
      openDate.setHours(hours, minutes, seconds, 0);

      if (openDate <= now) {
        openDate.setDate(openDate.getDate() + 1);
      }
      console.log("Scheduled refresh at:", openDate.toLocaleString());
      return openDate - now;
    };

    const timeUntilOpen = calculateTimeUntilOpen();
    if (timeUntilOpen !== null) {
      const timeoutId = setTimeout(() => {
        window.location.reload();
      }, timeUntilOpen);
      return () => clearTimeout(timeoutId);
    }
  }, [openTime]);

  const displayMessage =
    status === "preopen"
      ? "We are Opening Soon!"
      : status === "closed"
      ? "Closed Today"
      : statusmessage || "";

  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        width: "1920px",
        height: "1080px",
        backgroundColor: backgroundColor,
        color: "#fff",
      }}
    >
      {/* Apron Sidebar */}
      <div style={apronSidebarStyle}>
        <img src={apronImageUrl} alt="Apron" style={apronImageStyle} />
      </div>

      {/* Main Content */}
      <div style={{ padding: "3rem", width: "80%" }}>
        <h1 style={sectionTitleStyle}>{displayMessage}</h1>
        <p style={statusStyle}>{statusmessage}</p>
        {/* Display formatted opening time if the status is "preopen" */}
        {status === "preopen" && (
          <p style={statusStyle}>Opening at {formattedOpenTime}</p>
        )}
      </div>

      {/* Bottom Right Logo */}
      <img src={logoUrl} alt="Logo" style={logoStyle} />
    </div>
  );
};

export default ClosedSlide;
